<template>
  <div class="card card-custom">
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">
          {{ title }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary btn-hover" @click="openCreate">
          <i class="fal fa-plus"></i>
          {{ $t("workflowElements.createElement") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="getElementsByType">
        <b-table
          responsive
          :items="elements"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(icon)="data">
            <img
              v-if="data.item.icon && data.item.icon.link"
              class="border border-dark"
              :src="data.item.icon.link"
              height="60px"
              alt=""
            />
          </template>
          <template #cell(description)="data">
            <span class="description" v-html="data.item.description"></span>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex flex-nowrap">
              <button
                class="btn btn-icon btn-light btn-sm mr-1"
                @click="openDetails(data.item.id)"
              >
                <i class="fal fa-pen" />
              </button>
            </div>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import WorkflowElements from "@/components/Admins/Settings/WorkflowElements/workflowElements";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  components: { TableWrapper },
  props: {
    type: String,
    title: String
  },
  data() {
    return {
      elements: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "name",
          label: this.$t("workflowElements.name"),
          sortable: true
        },
        {
          key: "label",
          label: this.$t("workflowElements.label"),
          sortable: true
        },
        {
          key: "description",
          label: this.$t("workflowElements.description"),
          sortable: true
        },
        {
          key: "icon",
          label: this.$t("workflowElements.icon"),
          sortable: true,
          thStyle: "width: 250px"
        },
        { key: "actions", label: this.$t("table.actions") }
      ],
      isBusy: false,
      // Meta info
      meta: {}
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.getElementsByType();
  },
  methods: {
    getElementsByType() {
      if (!this.type) return;
      this.isBusy = true;
      let params = this.requestParams();
      params.type = this.type;
      let cancelToken = this.cancelToken();
      WorkflowElements.getAll(params, cancelToken)
        .then(response => {
          this.elements = response.data.data;
          this.setMeta(response.data.meta);
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    openCreate() {
      this.$router.push({
        name: "adminSettingsWorkflowElementsTypeAction",
        params: { type: this.type, action: "create" }
      });
    },
    openDetails(id) {
      this.$router.push({
        name: "adminSettingsWorkflowElementsTypeAction",
        params: { type: this.type, action: id }
      });
    },
    getRequestParams(page, pageSize) {
      let params = {};
      if (page) params["page"] = page;
      if (pageSize) params["size"] = pageSize;
      return params;
    },
    setMeta(meta) {
      this.meta = meta;
      this.totalPages = meta.last_page;
      this.totalRecords = meta.total;
    },
    handlePageChange(value) {
      this.page = value;
      this.getElementsByType();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getElementsByType();
    }
  }
};
</script>

<style>
.description * {
  margin: 0 !important;
}
</style>
