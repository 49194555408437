<template>
  <div>
    <div v-if="$route.name === 'adminSettingsWorkflowElementsType'">
      <ElementTable
        :title="$t('workflowElements.connectors')"
        :type="elementType"
      />
    </div>
    <div v-else-if="$route.params.action === 'create'">
      <ElementCreate
        :title="$t('workflowElements.connectorsCreate')"
        :type="elementType"
      />
    </div>
    <div v-else-if="$route.params.action">
      <ElementDetails
        :title="$t('workflowElements.connectorsDetails')"
        :type="elementType"
      />
    </div>
  </div>
</template>

<script>
import { TYPE_CONNECTOR } from "@/components/Admins/Settings/WorkflowElements/types";
import ElementTable from "@/components/Admins/Settings/WorkflowElements/ElementTable";
import ElementCreate from "@/components/Admins/Settings/WorkflowElements/ElementCreate";
import ElementDetails from "@/components/Admins/Settings/WorkflowElements/ElementDetails";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  components: {
    ElementCreate,
    ElementTable,
    ElementDetails
  },
  props: [],
  data() {
    return {
      elementType: TYPE_CONNECTOR,
      isBusy: false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminSettingsWorkflowElements"),
        route: "/admin/settings/workflow-elements"
      },
      {
        title: this.$t("workflowElements.connectors"),
        route: "/admin/settings/workflow-elements/" + this.elementType
      }
    ]);
  },
  methods: {}
};
</script>
