<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("menu.adminSettingsWorkflowElements") }}
              </h3>
            </div>
          </div>
          <div class="card-body pt-4">
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mb-2"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="{ name: item.route, params: { type: item.type } }"
                  custom
                >
                  <a
                    :href="href"
                    class="navi-link py-4"
                    :class="{ active: $route.params.type === item.type }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row-fluid ml-lg-8 client-details">
        <div v-if="$route.params.type === types.component">
          <Components />
        </div>
        <div v-else-if="$route.params.type === types.connector">
          <Connectors />
        </div>
        <div v-else-if="$route.params.type === types.mapping">
          <Mappings />
        </div>
        <div v-else-if="$route.params.type === types.trigger">
          <Triggers />
        </div>
        <div v-else-if="$route.params.type === types.communication">
          <Communication />
        </div>
        <div v-else-if="$route.params.type === types.data">
          <Data />
        </div>
        <div v-else-if="$route.params.type === types.operator">
          <Operator />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  TYPE_TRIGGER,
  TYPE_CONNECTOR,
  TYPE_COMPONENT,
  TYPE_MAPPING,
  TYPE_DATA,
  TYPE_COMMUNICATION,
  TYPE_OPERATOR
} from "@/components/Admins/Settings/WorkflowElements/types";

import Components from "@/components/Admins/Settings/WorkflowElements/Elements/Component";
import Connectors from "@/components/Admins/Settings/WorkflowElements/Elements/Connector";
import Mappings from "@/components/Admins/Settings/WorkflowElements/Elements/Mapping";
import Triggers from "@/components/Admins/Settings/WorkflowElements/Elements/Trigger";
import Communication from "@/components/Admins/Settings/WorkflowElements/Elements/Communication";
import Data from "@/components/Admins/Settings/WorkflowElements/Elements/Data";
import Operator from "@/components/Admins/Settings/WorkflowElements/Elements/Operator";

export default {
  name: "AdminsSettingsWorkflowElementsIndex",
  components: {
    Components,
    Connectors,
    Mappings,
    Triggers,
    Communication,
    Data,
    Operator
  },
  data() {
    return {
      tabIndex: 0,
      types: {
        trigger: TYPE_TRIGGER,
        connector: TYPE_CONNECTOR,
        component: TYPE_COMPONENT,
        mapping: TYPE_MAPPING,
        data: TYPE_DATA,
        communication: TYPE_COMMUNICATION,
        operator: TYPE_OPERATOR
      },
      tabNavigation: [
        {
          route: "adminSettingsWorkflowElementsType",
          type: TYPE_COMPONENT,
          label: "workflowElements.components",
          icon: "fal fa-grid-2-plus"
        },
        {
          route: "adminSettingsWorkflowElementsType",
          type: TYPE_CONNECTOR,
          label: "workflowElements.connectors",
          icon: "fal fa-grid-2-plus"
        },
        {
          route: "adminSettingsWorkflowElementsType",
          type: TYPE_MAPPING,
          label: "workflowElements.mappings",
          icon: "fal fa-grid-2-plus"
        },
        {
          route: "adminSettingsWorkflowElementsType",
          type: TYPE_TRIGGER,
          label: "workflowElements.triggers",
          icon: "fal fa-grid-2-plus"
        },
        {
          route: "adminSettingsWorkflowElementsType",
          type: TYPE_COMMUNICATION,
          label: "workflowElements.communication",
          icon: "fal fa-grid-2-plus"
        },
        {
          route: "adminSettingsWorkflowElementsType",
          type: TYPE_DATA,
          label: "workflowElements.data",
          icon: "fal fa-grid-2-plus"
        },
        {
          route: "adminSettingsWorkflowElementsType",
          type: TYPE_OPERATOR,
          label: "workflowElements.operators",
          icon: "fal fa-ellipsis-v"
        }
      ]
    };
  },
  watch: {
    $route: function () {
      this.checkRoute();
    }
  },
  mounted() {
    this.checkRoute();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminSettingsWorkflowElements"),
        route: "/admin/settings/workflow-elements"
      }
    ]);
  },
  methods: {
    checkRoute() {
      if (this.$route.name === "adminSettingsWorkflowElements")
        this.$router.push({
          name: "adminSettingsWorkflowElementsType",
          params: { type: TYPE_COMPONENT }
        });
    }
  }
};
</script>
