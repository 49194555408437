import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";
let url = process.env.VUE_APP_API_ADMIN;

ApiService.setHeader("x-api-key", store.getters.apiToken);

class WorkflowElements {
  get(id) {
    return ApiService.get(url, "workflow_elements/" + id);
  }
  getAll(params, cancelToken) {
    let options = new URLSearchParams(params).toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.get(url, "workflow_elements?" + options, headers);
  }
  store(data) {
    return ApiService.post(url + "/workflow_elements", data);
  }
  update(id, data) {
    return ApiService.put(url + "/workflow_elements/" + id, data);
  }
}
export default new WorkflowElements();
