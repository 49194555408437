<template>
  <div class="card card-custom">
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ title }}</h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-secondary btn-hover ml-1" @click="cancel">
          {{ $t("general.cancel") }}
        </button>
        <button
          class="btn btn-primary btn-hover"
          :disabled="edit"
          @click="updateElement"
        >
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body">
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold">
            {{ $t("workflowElements.elementData") }}:
          </h5>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("workflowElements.name") }}*
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <input
            v-model="$v.element.name.$model"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.element.name.$error }"
          />
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("workflowElements.label") }}*
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <input
            v-model="$v.element.label.$model"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.element.label.$error }"
          />
        </div>
      </div>
      <div class="separator separator-dashed my-6"></div>
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold">
            {{ $t("workflowElements.layoutOptions") }}:
          </h5>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("workflowElements.width") }}*
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <input
            v-model="$v.element.width.$model"
            type="number"
            step="1"
            min="1"
            class="form-control"
            :class="{ 'is-invalid': $v.element.width.$error }"
          />
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("workflowElements.height") }}*
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <input
            v-model="$v.element.height.$model"
            type="number"
            step="1"
            min="1"
            class="form-control"
            :class="{ 'is-invalid': $v.element.height.$error }"
          />
        </div>
      </div>
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <span class="text-muted">
            {{ $t("workflowElements.aspectRatioHint") }}
          </span>
        </div>
      </div>
      <div class="form-group row align-items-start">
        <label class="col-xl-3 col-lg-3 col-form-label">
          <div class="mt-3">{{ $t("workflowElements.description") }}</div>
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <QuillEditor
            ref="quill"
            v-model="element.description"
            :options="quillOptions"
          />
        </div>
      </div>
      <div class="form-group row align-items-start">
        <label class="col-xl-3 col-lg-3 col-form-label">
          <div class="mt-3">{{ $t("workflowElements.icon") }}</div>
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <ImageUploadCropper
            :aspect-ratio="aspectRatio"
            :default-image="elementIcon"
            :upload-button-text="$t('workflowElements.iconUpload')"
            :crop-button-text="$t('workflowElements.iconCrop')"
            :remove-button-text="$t('workflowElements.iconRemove')"
            :drop-area-text="$t('workflowElements.iconDropArea')"
            @cropMode="edit = $event"
            @fileUpdated="files = $event"
            @fileRemoved="fileRemoved"
          >
          </ImageUploadCropper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { quillEditor as QuillEditor } from "vue-quill-editor";
import ImageUploadCropper from "@/components/Admins/Media/ImageUploadCropper";
import Swal from "sweetalert2";
import WorkflowElements from "@/components/Admins/Settings/WorkflowElements/workflowElements";
import Media from "@/components/Admins/Media/media";

export default {
  components: {
    QuillEditor,
    ImageUploadCropper
  },
  props: {
    title: String,
    type: String
  },
  data() {
    return {
      quill: null,
      element: {
        name: "",
        label: "",
        description: "",
        icon: null,
        icon_id: null,
        type: this.type,
        width: 1,
        height: 1,
        config: []
      },
      quillContent: "",
      quillOptions: {
        theme: "snow",
        placeholder: this.$t("workflowElements.descriptionPlaceholder")
      },
      isBusy: false,
      // Cropper attributes
      edit: false,
      files: [],
      elementIcon: ""
    };
  },
  validations: {
    element: {
      name: { required },
      label: { required },
      type: { required },
      width: { required },
      height: { required }
    }
  },
  computed: {
    aspectRatio: function () {
      if (this.element.width && this.element.height)
        return this.element.width / (this.element.height / 2);
      else return 1;
    }
  },
  mounted() {
    if (!parseInt(this.$route.params.action)) this.cancel();
    this.getElement();
  },
  methods: {
    cancel() {
      this.$router.push({
        name: "adminSettingsWorkflowElementsType",
        params: { type: this.type }
      });
    },
    getElement() {
      this.isBusy = true;
      let elementId = this.$route.params.action;
      WorkflowElements.get(elementId)
        .then(response => {
          this.element = response.data.data;
          if (this.element.icon) {
            this.element.icon_id = this.element.icon.id;
            this.elementIcon = this.element.icon.link;
          }
          this.element.config = this.element.config ?? [];
          this.isBusy = false;
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          }).then(() => {
            if (error.response.status === 404) {
              this.cancel();
            }
          });
          this.isBusy = false;
        });
    },
    async updateElement() {
      this.$v.$touch();
      if (this.$v.$invalid || this.edit) return;
      this.isBusy = true;
      if (this.files.length) {
        let mediaData = new FormData();
        mediaData.append("files[]", this.files[0].file);
        mediaData.append("description[]", "Workflow Component Icon");
        await Media.store(mediaData)
          .then(response => {
            if (response.data.data.success) {
              if (this.element.icon) this.deleteIcon();
              this.element.icon_id = response.data.data.uploaded[0].id;
            }
          })
          .catch(error => {
            Swal.fire({
              title: this.$t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
            this.isBusy = false;
          });
      }
      if (!this.files.length && !this.elementIcon && this.element.icon) {
        this.element.icon_id = null;
        this.toDelete = this.element.icon.id;
      }
      delete this.element.icon;
      WorkflowElements.update(this.element.id, this.element)
        .then(() => {
          if (this.toDelete) this.deleteIcon(true);
          this.$toast.fire({
            icon: "success",
            title: this.$t("workflowElements.updatedSuccessfully")
          });
          this.getElement();
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.getElement();
        });
    },
    deleteIcon(useToDelete = false) {
      let iconId = null;
      iconId = useToDelete ? this.toDelete : this.element.icon.id;
      if (iconId) {
        Media.delete(iconId);
      }
    },
    fileRemoved() {
      this.files = [];
      this.elementIcon = false;
    }
  }
};
</script>

<style lang="scss">
.img-thumbnail {
  border: 1px solid;
}
</style>
